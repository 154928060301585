import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory, Link, useParams } from 'react-router-dom';

import Space from 'components/Space';
import Button from 'components/Button';
import Text from 'components/Text';

import Summary from './Summary';
import Drivers from '../shared/Drivers';

import Main from './Main';
import Implements from './Implements';
import Data from './Data';
import Owner from './Owner';
import Attachments from './Attachments';
import { Header } from 'components';
import { useVehicle } from '../shared/context';
import Container from "react-bootstrap/Container";

export default function VehicleDetails() {
  const history = useHistory();
  const vehicle = useVehicle();
  const {vehicle_id} = useParams();
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    if (vehicle_id) {
      vehicle.setPrevVehicle(vehicle.data);
    }
  }, [vehicle_id]);

  return (
    <>
    <Header
      title={`Veículo ${vehicle.data.plate || ''}`}
      RightComponent={
        <Space x="4px">
          <Button variant="secondary" onClick={() => history.goBack()}>
            <Text color="dark" weight={500}>
              Voltar
            </Text>
          </Button>
          <Link to="/veiculos/cadastrar">
            <Button>
              <Text color="white" weight={500}>
                Novo veículo
              </Text>
            </Button>
          </Link>
        </Space>
      }/>
      <Container>
        <Row>
          <Col>
            <Summary />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Main />
            <Drivers />
          </Col>
          <Col xs={12} md={6}>
            <Implements />
            <Data />
            <Owner />
          </Col>
        </Row>
        <Row>
          <Attachments
            attachments={attachments}
            setAttachments={setAttachments}
            />
        </Row>
      </Container>
    </>
  );
}
