import Types from './types';

const Actions = {
  signOut: history => ({
    type: Types.SIGN_OUT,
    payload: { history },
  }),
};

export default Actions;
