import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { UpdatePasswordPage } from 'pages/UpdatePassword';
import { getToken, getUser, authenticated } from 'services/api/auth';


export default function PrivateRoute({ component: Component, ...rest }) {
  const token = getToken()
  const isAuthenticated = authenticated();
  const location = useLocation();

  if (!token || !isAuthenticated) {
    return (
      <Redirect to={{ pathname: '/login', state: { from: location.pathname } }} />
    )

  }

  const user = getUser()
  const lastPasswordUpdate = user?.last_update_password
  const isUserActive = user?.active
  const isUserBlocked = user?.blocked
  const shouldUserChangePassword =
    user?.change_password === true || user?.change_password === false
      ? user?.change_password
      : true;

  const currentDate = new Date();
  const sixMonthsAgo = new Date(currentDate);
  sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
  const lastPasswordUpdateInDate = new Date(lastPasswordUpdate);
  const isLastUpdateLessThanSixMonths = lastPasswordUpdateInDate > sixMonthsAgo;
  const shouldRedirectToChangePasswordModal =
    isUserActive &&
    isAuthenticated &&
    !isUserBlocked &&
    (shouldUserChangePassword || !isLastUpdateLessThanSixMonths);

  if (shouldRedirectToChangePasswordModal) {
    return (
      <Route {...rest} path="conta/atualizar" component={UpdatePasswordPage} />
    );
  }

  if (isAuthenticated && isUserActive && !isUserBlocked) {
    return (
      <Route
        {...rest}
        render={props => <Component {...props} />}
      />
    );
  }
}