import { takeLatest, call, put, all } from 'redux-saga/effects';
// eslint-disable-next-line import/no-cycle
import { persistor } from 'store';
// eslint-disable-next-line import/no-cycle
import api from 'services/api';

import Types from './types';

function signOut({ payload }) {
  const { history } = payload;

  history.push('/login');
  persistor.purge();
  localStorage.clear()
}

export default all([
  takeLatest(Types.SIGN_OUT, signOut),
]);
