
export const loadRecaptcha = async () => {
  return new Promise((resolve, reject) => {
    if (window.grecaptcha) {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute('6Ld3Ec4qAAAAAE4Ao5zjdsFycQxD6PH4hXUrR0XN', { action: "submit" })
          .then((token) => resolve(token))
          .catch((error) => reject(error));
      });
    } else {
      reject("reCAPTCHA não está carregado.");
    }
  });
};
