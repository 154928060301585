import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { getUser } from 'services/api/auth';

// eslint-disable-next-line import/no-cycle
import Header from 'components/Header';

export default function Home() {
  const user = getUser()

  return (
    <>
      <Header />
      <Container>
        <Card>
          <Card.Body className="py-5">
            <Card.Title>Olá, {user?.username}.</Card.Title>
            <hr />
            <Col className="pt-4">
              <p>
                Bem vindo ao portal Cooperplace para transportadoras, aqui você
                pode cadastrar seus veículos, motoristas, acompanhar suas
                viagens e negociar novos fretes.
              </p>
              <p>
                Caso tenha alguma dúvida ou questionamento pode entrar em contato conosco neste{' '}
                <a href="https://coopercarga.agidesk.com/br/formulario/355?app_token=67811bc274ea5_FR355&embed" target='_blank'>Link</a>.
              </p>
            </Col>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}
