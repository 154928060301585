import { jwtDecode } from "jwt-decode";

const TOKEN_KEY = 'token';
const GET_USER = 'user'

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getUser =() => JSON.parse(localStorage.getItem(GET_USER)) 
export const authenticated = () => {
    const token = localStorage.getItem(TOKEN_KEY);
  
    if (!token) {
      return false;
    }
  
    try {
  
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
  
      if (decodedToken.exp && decodedToken.exp < currentTime) {
        localStorage.removeItem(TOKEN_KEY);
        return false
      }
  
      return true;
    } catch (error) {
      console.error('Erro ao decodificar o token:', error);
      localStorage.removeItem(TOKEN_KEY);
      return false;
    }
  };