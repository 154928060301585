import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import FormControl from 'react-bootstrap/FormControl';
import Loader from 'components/Loader';
import Header from 'components/Header';
import Chat from './Chat';
import DealHeader from './HeaderDeal';

import DealsActions from 'store/modules/deals/actions';

import { formatDate } from 'utils/formatter';
import { getUser } from 'services/api/auth';

import './style.css';
import dealSocket from 'services/api/deal/socket';

export default function List() {
  const [deal, setDeal] = useState({});
  const [selectedDeal, setSelectedDeal] = useState('');
  const [filteredDeals, setFilteredDeals] = useState([]);
  const [chatReload, setChatReload] = useState(null)
  const [chatClosedReload, setClosedReload] = useState(null)
  const dispatch = useDispatch();
  const deals = useSelector(store => store.deals);
  const user = getUser()

  function clickDeal(deal) {
    setDeal({ ...deal })
    markDealNewMessage(deal.id, false)
  }

  function markDealNewMessage(incoming, newMessage) {
    setFilteredDeals(oldDealList =>
      oldDealList.map(deal => {
        if (deal.id === incoming) {
          return {
            ...deal,
            unreadCount: newMessage ? (deal.unreadCount || 0) + 1 : 0,
          };
        }
        return deal;
      })
    );
  }

  function filterDeal(filter) {
    if (!filter) {
      setFilteredDeals(deals.data);
      return;
    }
    const filtered = filteredDeals.filter(deal => {
      const string = `${deal.id} ${deal.origin_city} ${deal.origin_province} ${deal.last_destination_city} ${deal.last_destination_province}`;
      return string.includes(filter);
    });
    setFilteredDeals(filtered);
  }

  useEffect(() => {
    dealSocket.on('reloadChat', args => {
      if (args.receiver_id === user.id) {
        setChatReload(args.load_id)

        if (args.load_id === deal.load_id) {
          setDeal({})
        }
      }
    })

    dealSocket.on('reloadClosedChat', args => {
      if (args.receiver_id === user.id) {
        setClosedReload(args.deal_id)

        if (args.deal_id === deal.id) {
          setDeal({})
        }
      }
    })

    return () => {
      dealSocket.removeEventListener('reloadChat');
      dealSocket.removeEventListener('reloadClosedChat');
    };
  }, [deal]);

  useEffect(() => {
    dispatch(
      DealsActions.dealsRequest({
        user_id: user.id,
        paginate: true,
        only_active: true,
      })
    );
  }, [chatReload, chatClosedReload]);

  useEffect(() => {
    if (deals.data) {
      setFilteredDeals(deals.data);
    }
  }, [deals, chatReload, chatClosedReload]);

  useEffect(() => {
    setSelectedDeal(deal.id);
  }, [deal, chatReload, chatClosedReload]);

  const hasDeals = useMemo(() => deals.fetched && filteredDeals.length > 0, [
    deals,
    filteredDeals,
  ]);
  const loading = useMemo(() => deals.fetched, [deals]);

  return (
    <>
      <Header />
      <Container className="mh-100">
        <Card className="card-list">
          <Card.Body className="p-0">
            <Container>
              <DealHeader deal={deal} />
              <Row>
                <Col lg={4} md={4} xs={12} className="px-0">
                  <div className="chat-filter p-3">
                    <FormControl
                      placeholder="Filtrar"
                      onChange={event => filterDeal(event.target.value)}
                    />
                  </div>
                  <div className="chat-list p-0" >
                    {filteredDeals.map((deal, index) => {
                      const itemColor =
                        selectedDeal === deal.id ? '#007bff' : '';
                      return (
                        <Card
                          key={index}
                          action
                          onClick={() => clickDeal(deal)}
                          style={{
                            borderColor: itemColor,
                          }}
                          className="chat-selection"
                        >
                          <Card.Body>
                            <Container>
                              <Row>
                                <Col className="px-0">
                                  <div className="card-label">
                                    #{deal.load_id}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12} className="px-0">
                                  <hr />
                                  <div className="card-label">Origem</div>

                                  <div className="card-text pb-0">{`${deal.origin_city} - ${deal.origin_province}`}</div>
                                  <div className="card-text pb-3">
                                    {formatDate(deal.origin_scheduled_time)}
                                  </div>

                                  <div className="card-label">Destino</div>
                                  <div className="card-text pb-0">{`${deal.last_destination_city} - ${deal.last_destination_province}`}</div>
                                  <div className="card-text pb-0">
                                    {formatDate(
                                      deal.last_destination_scheduled_time
                                    )}
                                  </div>
                                </Col>
                                <Col>
                                  {deal.unreadCount ? (
                                    <div
                                      title={`${deal.unreadCount === 1
                                        ? `${deal.unreadCount} mensagem não lida`
                                        : `${deal.unreadCount} mensagens não lidas`
                                        }`}
                                      className="notification"
                                    >
                                      {deal.unreadCount}
                                    </div>
                                  ) : null}
                                </Col>
                              </Row>
                            </Container>
                          </Card.Body>
                        </Card>
                      );
                    })}
                    {loading ? (
                      <Card className={`d-${hasDeals ? 'none' : 'block'}`}>
                        <Card.Body>
                          <Container>
                            <Row>
                              <Col className="text-center">
                                <div className="card-label mb-0">
                                  Nenhuma negociação encontrada.
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </Card.Body>
                      </Card>
                    ) : (
                      <Col style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                        <Row>
                          <Loader loading={true} size='lg' />
                        </Row>
                      </Col>
                    )}
                  </div>
                </Col>

                <Col lg={8} md={8} xs={12} className="px-0">
                  <Chat deal={deal} user={user} markDealNewMessage={markDealNewMessage} />
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}