import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { AiOutlineArrowLeft } from 'react-icons/ai';

import Input from 'components/Input';
import Button from 'components/Button/Login';
import Text from 'components/Text'
import Stepper from 'components/Stepper';
import { loadRecaptcha } from 'services/reCaptcha'

import api from 'services/api';

import { Logo } from './styles';
import apiCooperplace from 'services/api/cooperplace';

import { useSnackbar } from 'components/SnackbarV3';
import { validate } from './validate'

export default function Login() {
  const Steps = {
    LOGIN: 0,
    FORGOT_PASSWORD: 1,
  };

  const initialStep = Steps.LOGIN;
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [loading, setLoading] = useState(false);
  const [error, setErrors] = useState({});
  const [data, setData] = useState({
    email: '',
    password: '',
  });

  const snackbar = useSnackbar();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js?render=6Ld3Ec4qAAAAAE4Ao5zjdsFycQxD6PH4hXUrR0XN";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }, [])

  async function submit() {
    setLoading(true);
    try {

      const isDataValid = await validate(data, setErrors, 'LOGIN');

      if (!isDataValid) {
        snackbar.show(<Text>E-mail e senha obrigatórios</Text>, {
          type: 'error',
        });
        setLoading(false);
        return;
      }

      const recaptchaToken = await loadRecaptcha();

      const response = await apiCooperplace.post('sessions/login/carrier-web', {
        email: data.email,
        password: data.password,
        recaptchaToken: recaptchaToken
      });

      const user = response.data.user;
      const token = response.data.token;

      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('helpNotification', JSON.stringify({ notification: true }));

      axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;

      window.location.href = '/';


    } catch (ex) {

      const message = ex?.response?.data?.error

      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  async function doPasswordRecovery() {
    setLoading(true);
    try {

      const isDataValid = await validate({
        email_recovery: data.email
      }, setErrors, 'FORGOT_PASSWORD');

      if (!isDataValid) {
        snackbar.show(<Text>E-mail obrigatório</Text>, {
          type: 'error',
        });
        setLoading(false);
        return
      }

      await api.post('forgot-password', {
        email: data.email,
        type: 'carrier',
      });

      setCurrentStep(Steps.LOGIN);
      setData({
        ...data,
        email: ''
      })

      snackbar.show(<Text>Enviamos o link para alteração de senha para seu e-mail</Text>, {
        type: 'success',
      });

    } catch (ex) {
      setCurrentStep(Steps.LOGIN);
      setData({
        ...data,
        email: ''
      })
      snackbar.show(<Text>Enviamos o link para alteração de senha para seu e-mail</Text>, {
        type: 'success',
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (currentStep === Steps.LOGIN) {
      await submit();
    } else {
      await doPasswordRecovery();
    }
  }

  function changeStep(step) {
    setCurrentStep(step);
  }


  const steps = [
    {
      step: Steps.LOGIN,
      component: (
        <Row>
          <Col style={{ marginBottom: '1rem', display: 'flex', flexDirection: 'column', gap: '0.5rem', padding: '0' }}>
            <Input
              name="email"
              label={
                <div className="mb-2">
                  <Text color="primary" weight="500">
                    Entrar
                  </Text>
                </div>
              }
              placeholder="Para começar insira seu e-mail"
              value={data.email}
              type="email"
              error={error.email}
              onChange={event =>
                setData({
                  ...data,
                  email: event.target.value,
                })
              }
            />

            <Input
              name="password"
              placeholder="Insira sua senha"
              value={data.password}
              type="password"
              error={error.password}
              autoFocus
              onChange={event =>
                setData({
                  ...data,
                  password: event.target.value,
                })
              }
            />
          </Col>
          <Button
            variant="primary"
            type="submit"
            className="w-100"
            loading={loading}
          >
            {'Entrar'}
          </Button>

          <Button
            as="a"
            className="w-100 text-center d-block text-center mb-1 mt-1 text-decoration-none"
            variant="success"
            target="_blank"
            href={process.env.REACT_APP_REGISTER_FORM_URL}
          >
            Solicitar cadastro
          </Button>

          <div>
            <Button
              role="button"
              as="a"
              variant="link"
              className="forgot-password d-block text-center"
              onClick={() => {
                setErrors({})
                changeStep(Steps.FORGOT_PASSWORD)
              }
              }
            >
              Esqueci minha senha
            </Button>
          </div>
        </Row>
      )
    },
    {
      step: Steps.FORGOT_PASSWORD,
      component: (
        <>
          <Col style={{ marginBottom: '1rem', padding: '0' }}>
            <Input
              name="email"
              label={
                <div className="mb-2">
                  <Text color="primary" weight="500">
                    Recuperar senha
                  </Text>
                </div>
              }
              placeholder="Insira o e-mail de login para recuperar a senha"
              value={data.email}
              type="email"
              onChange={event =>
                setData({
                  ...data,
                  email: event.target.value,
                })
              }
              error={error.email_recovery}
            />
          </Col>

          <Button
            variant="primary"
            type="submit"
            className="w-100"
            loading={loading}
          >
            {'Enviar e-mail'}
          </Button>
          <Button
            as="a"
            className="w-100 text-center d-block text-center mb-1 mt-1 text-decoration-none"
            variant="success"
            target="_blank"
            href={process.env.REACT_APP_REGISTER_FORM_URL}
          >
            Solicitar cadastro
          </Button>
        </>
      )
    }
  ]

  return (
    <form onSubmit={handleSubmit}>
      {
        currentStep === Steps.FORGOT_PASSWORD && (
          <Row className="justify-content-left">
            <Button
              variant="link"
              className="forgot-arrow"
              onClick={() => {
                setErrors({})
                changeStep(Steps.LOGIN)
              }}
            >
              <AiOutlineArrowLeft />
            </Button>
          </Row>
        )
      }
      <Row className="justify-content-center py-4" >
        <Logo src="/images/Coopercarga-log.png" alt="Coopercarga" />
        <Logo src="/images/Logo-CooperPlace.png" alt="Cooperplace" />
      </Row>
      <Stepper steps={steps} currentStep={currentStep} />
    </form>
  );
}