/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Col, Row } from 'react-bootstrap';

import api from 'services/api';

import Modal from 'components/Modal';
import Radio from 'components/Radio';
import Select from 'components/Select';
import Text from 'components/Text';
import Button from 'components/Button';
import { useSnackbar } from 'components/SnackbarV3';
import { useVehicle } from '../../context';
import apiCooperplace from 'services/api/cooperplace';

function Drivers({ isOpen, onClose }) {
  const vehicle = useVehicle();
  const { vehicle_id } = useParams();
  const snackbar = useSnackbar();
  const [driver, setDriver] = useState();
  const [driversOptions, setDriversOptions] = useState([]);
  const [mainDriver, setMainDriver] = useState(
    vehicle.data.drivers.length === 0
  );

  const hasCurrentDriver = vehicle.data.drivers.some(item => item.current);

  const [errors, setErrors] = useState('');

  async function fetchDrivers(search) {
    try {
      const response = await apiCooperplace.get('carrierWeb/driver/select',
        {
          params: { name: search },
        }
      );
      return response.data;
    } catch (ex) {
      return [];
    }
  }

  useEffect(() => {
    fetchDrivers().then(setDriversOptions)
  }, []);

  async function handleAddDriver(event) {
    event.preventDefault();

    const getIdentifier = item => item?.id || item?.value;

    if (
      !vehicle.data.currentDriverEditing &&
      vehicle.data.drivers.map(getIdentifier).includes(getIdentifier(driver))
    ) {
      setErrors('Esse motorista já foi selecionado');
      return;
    }

    const currentDrivers = vehicle.data?.drivers || [];

    try {
      let updatedDrivers = (vehicle.data?.drivers || []).filter(
        drivers => getIdentifier(drivers) !== getIdentifier(driver)
      );
      const currentDriver = currentDrivers.find(item => item.current);

      if (mainDriver)
        updatedDrivers = updatedDrivers.map(item => {
          if (getIdentifier(item) === getIdentifier(currentDriver)) {
            return { ...item, current: false };
          }

          return item;
        });

      if (vehicle_id) {
        if (currentDriver && mainDriver) {
          await api.put(`v3/vehicle/driver/${vehicle_id}`, {
            driver_id: getIdentifier(currentDriver),
            current: false,
            wagons_id: vehicle.data.implements.map(wagon => wagon.id),
          });
        }

        await api.put(`v3/vehicle/driver/${vehicle_id}`, {
          driver_id: getIdentifier(driver),
          current: mainDriver,
          wagons_id: vehicle.data.implements.map(wagon => wagon.id),
        });

        vehicle.setPrevVehicle(old => ({
          ...old,
          drivers: [
            ...updatedDrivers,
            {
              ...driver,
              current: mainDriver,
            },
          ],
        }));
      }

      vehicle.setData({
        drivers: [
          ...updatedDrivers,
          {
            ...driver,
            current: mainDriver,
          },
        ],
      });
    } catch (error) {
      snackbar.show(<Text>Não foi possível editar os motoristas.</Text>, {
        type: 'error',
      });
    } finally {
      onClose(true);
      setDriver('');
      setErrors();
      vehicle.setData({
        currentDriverEditing: null,
      });
      setMainDriver(false);
    }
  }

  useEffect(() => {
    if (hasCurrentDriver) {
      setMainDriver(false);
    }
    if (vehicle.data.drivers.length === 0) setMainDriver(true);
  }, [vehicle.data.drivers]);

  useEffect(() => {
    if (vehicle.data.currentDriverEditing) {
      setDriver(vehicle.data.currentDriverEditing);
      setMainDriver(vehicle.data.currentDriverEditing.current);
    }

    if (!vehicle.data.currentDriverEditing) {
      setDriver(null);
    }
  }, [vehicle.data.currentDriverEditing]);

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        heading={
          <Text color="dark" type="header">
            Motorista
          </Text>
        }
        body={
          <form onSubmit={handleAddDriver}>
            <Row>
              <Col xs={12}>
                <Select
                  label="Nome"
                  options={driversOptions}
                  value={driver}
                  onChange={value => {
                    if (value) {
                      setDriver({ ...value, value: value.id });
                    } else {
                      setDriver(value);
                    }
                  }}
                  getOptionLabel={option => option.label}
                  getOptionValue={option => option.id}
                  error={errors || vehicle.errors.drivers}
                  isDisabled={!!vehicle.data.currentDriverEditing}
                />
              </Col>
              {vehicle.data.drivers.length === 0 ? null : (
                <Col xs={12} className="mt-3">
                  <Radio.Group
                    label="Tornar esse motorista atual"
                    onChange={({ target }) => setMainDriver(target.value)}
                    value={mainDriver}
                    horizontal
                  >
                    <Radio value>
                      <Text color="dark" type="label">
                        Sim
                      </Text>
                    </Radio>
                    <Radio value={false}>
                      <Text color="dark" type="label">
                        Não
                      </Text>
                    </Radio>
                  </Radio.Group>
                </Col>
              )}
              <Col
                xs={12}
                className="align-items-center d-flex justify-content-center my-4"
              >
                <Button type="submit" disabled={!driver}>
                  {vehicle.data.currentDriverEditing ? 'Editar' : 'Adicionar'}
                </Button>
              </Col>
            </Row>
          </form>
        }
      />
    </>
  );
}

export default Drivers;
