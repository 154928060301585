const Yup = require('yup');

export const login = Yup.object().shape({
  email: Yup.string()
    .required('E-mail obrigatório')
    .typeError('E-mail obrigatório'),
  password: Yup.string()
    .required('Senha obrigatória')
    .typeError('Senha obrigatória'),

});

export const passwordRecovery = Yup.object().shape({
  email_recovery: Yup.string()
    .required('E-mail obrigatório')
    .typeError('E-mail obrigatório'),
})

export async function validate(data, setErrors, type, context) {
  const errorList = {};
  try {
    if (type === 'LOGIN') {
      await login.validate(data, {
        abortEarly: false,
        context,
      });
    } else {
      await passwordRecovery.validate(data, {
        abortEarly: false,
        context,
      });
    }
    setErrors(errorList);
    return true;
  } catch (err) {
    err.inner.forEach(error => {
      errorList[error.path] = error?.message;
    });
    setErrors(errorList);
    return false;
  }
}
