import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  user: null,
  valid: false,
  authenticated: false,
  loading: false,
};

export default function auth(state = INITIAL_STATE, { type, payload }) {
  return produce(state, draft => {
    switch (type) {
      case Types.SIGN_OUT:
        draft.loading = false;
        draft.valid = false;
        draft.user = null;
        draft.authenticated = false;
        break;
      default:
        break;
    }
  });
}
